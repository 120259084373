import './Faq.scss';

import cx from 'classnames';
import { gsap } from 'gsap';
import { forwardRef, memo, useEffect, useRef, useState } from 'react';

import { ReactComponent as FaqPlusIcon } from 'assets/icons/faqplus.svg';

const faq = [
  {
    question: '어떤 서비스를 제공하시나요?',
    answer: '웹사이트와 모바일 앱 모두 개발 가능합니다.',
  },
  {
    question: '디자인도 가능한가요?',
    answer:
      '드문 경우이기는 하지만 상대적으로 규모가 크지 않은 프로젝트일 경우 디자인 서비스를 제안드릴수도 있습니다.',
  },
  {
    question: '어떤 개발 기술을 사용하시나요?',
    answer:
      '최신 웹/앱 개발 기술을 능숙하게 다룹니다. 하지만 웹사이트의 경우 React나 Next.js를 앱의 경우 React Native를 추천드립니다. 빠르고 안정적인 개발 환경을 위해 백엔드는 AWS에 Node.js Express를 사용하고 있습니다.',
  },
  {
    question: '프로젝트 소요 시간은 어느 정도인가요?',
    answer:
      '지정된 작업 마감일을 준수하는 것을 최우선으로 하고 있으며 대부분의 프로젝트는 1주에서 6주 정도의 시간이 소요됩니다.',
  },
  {
    question: '프로젝트 진행 비용은 얼마인가요? ',
    answer:
      '프로젝트의 견적의 폭은 작업의 복잡성에 기반하여 상당히 넓은 편이며, 또한 단기인지 장기인지에 따라서도 변동이 생길 수 있습니다. 그래서 이 문의에 대한 답변을 정확하게 드리기는 어렵지만 일반적으로 정규 근무시간 기준 1달 정도 소요될 경우 800만원으로 생각하시면 될것 같습니다.',
  },
  {
    question: '상주 및 오프라인 미팅 가능한가요?',
    answer:
      '외주 작업만 가능하며 필요한 경우 전화상담 및 비대면 회의 진행 가능합니다.',
  },
  {
    question:
      '다른 개발자가 작업한 프로젝트가 있습니다. 전달받아 연계 작업이 가능한가요?',
    answer:
      '리뉴얼 프로젝트가 아닌 이상, 다른 개발자의 작업으로 프로젝트를 재진행할 확률은 아주 낮은 편입니다.',
  },
  {
    question: '결제는 어떻게 진행되며 세금계산서 발행이 가능한가요?',
    answer:
      '고객님과 상담을 통해 견적이 합의되면 크몽 플랫폼 내 안전결제를 통해 작업에 대한 결제가 이루어집니다. 세금계산서는 요청시 작업물 전송 후 구매확정 시 발급됩니다.',
  },
];

interface FaqItemProps {
  question: string;
  answer: string;
  index: number;
  activeItem: number | null;
  setActiveItem: React.Dispatch<React.SetStateAction<number | null>>;
}

const FaqItem = memo(
  ({ question, answer, index, activeItem, setActiveItem }: FaqItemProps) => {
    const ref = useRef<HTMLLIElement>(null);

    useEffect(() => {
      gsap.to(ref.current!.querySelector('.faq-content'), {
        height: activeItem === index ? 'auto' : 0,
        opacity: activeItem === index ? 1 : 0,
        duration: 0.3,
      });
      gsap.to(ref.current!.querySelector('.faq-icon'), {
        rotateZ: activeItem === index ? 45 : 0,
        duration: 0.3,
      });
    }, [activeItem]);

    const onToggle = () => {
      setActiveItem((item) => (item === index ? null : index));
    };

    return (
      <li
        ref={ref}
        className={cx('faq-item', { active: index === activeItem })}
      >
        <div className="faq-header" onClick={onToggle}>
          <h3 className="faq-question">{question}</h3>
          <div className="faq-icon-wrap">
            <div className="faq-icon">
              <FaqPlusIcon />
            </div>
          </div>
        </div>
        <div className="faq-content">
          <div className="faq-content-inner">
            <p>{answer}</p>
          </div>
        </div>
      </li>
    );
  },
);

const Faq = forwardRef<HTMLElement>((_, ref) => {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  return (
    <section ref={ref} className="section faq-sec">
      <div className="page-padding">
        <div className="section-header section-header--faq">
          <h2 className="section-heading">자주 묻는 질문</h2>
        </div>
        <div className="container">
          <ul>
            {faq.map(({ question, answer }, index) => (
              <FaqItem
                key={question}
                question={question}
                answer={answer}
                index={index}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
});

Faq.displayName = 'Faq';

export default Faq;
