import './BenefitsStats.scss';

import Card from 'components/Card';

const BenefitsStats = () => {
  return (
    <div className="benefits-stats">
      <Card cardClassName="intro-card--stats-cell">
        <div className="stats-figure-wrap">
          <span className="intro-stats-figure">75</span>
          <span className="intro-stats-symbol intro-stats-symbol--plus">+</span>
        </div>
        <div className="stats-description">고객 만족</div>
      </Card>
      <Card cardClassName="intro-card--stats-cell">
        <div className="stats-figure-wrap">
          <span className="intro-stats-figure">10</span>
          <span className="intro-stats-symbol intro-stats-symbol--years">
            년
          </span>
        </div>
        <div className="stats-description">숙련된 경험</div>
      </Card>
      <Card cardClassName="intro-card--stats-cell">
        <div className="stats-figure-wrap">
          <span className="intro-stats-figure">115</span>
          <span className="intro-stats-symbol intro-stats-symbol--plus">+</span>
        </div>
        <div className="stats-description">성공적인 프로젝트</div>
      </Card>
      <Card cardClassName="intro-card--stats-cell">
        <div className="stats-figure-wrap">
          <span className="intro-stats-figure">100</span>
          <span className="intro-stats-symbol intro-stats-percent">%</span>
        </div>
        <div className="stats-description">열정</div>
      </Card>
    </div>
  );
};

export default BenefitsStats;
